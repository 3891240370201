<ng-container *ngIf="(basicSettings.state$ | async)?.show_contact_us_button">
  <yeda-fixed-call-form [college]="college"></yeda-fixed-call-form>
</ng-container>

<router-outlet />

<div
  airText2
  style="
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: white;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  "
  *ngIf="!engineeringWorks"
>
  <span
    style="
      padding: 20px;
      margin: 30px auto;
      border: 1px solid #cfcfcf;
      width: 100%;
      max-width: 700px;
      text-align: center;
    "
  >Sorry, technical work is in progress. Everything will work soon!</span
  >
</div>

@if (collegeEnvironmentState.state$ | async; as collegeEnvironment) {
  @if (!collegeEnvironment.collegeEnvironment.disconnect_college) {
    <yeda-modal-devices></yeda-modal-devices>

    <yeda-widget-support></yeda-widget-support>
    <yeda-visually-impaired></yeda-visually-impaired>

    <div id="main-captcha" class="recaptcha">
      <div id="captcha"></div>
    </div>
    <yeda-wl-admin-menu />
  }
}

