import { inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { CollegeEnvironmentRepository, environment } from 'libraries/env';
import { AuthRepository } from 'libraries/auth';

type AdminMenuDynamicPage = { icon: string; name: string; url: string };

function oldAdminPanelUrl(url: string): string {
  return `${environment.url}/collegeadmin/${url}`;
}

export function useDynamicPages(): Observable<AdminMenuDynamicPage[]> {
  const router = inject(Router);
  const authRepository = inject(AuthRepository);
  const collegeEnv = inject(CollegeEnvironmentRepository);
  return router.events.pipe(
    filter((e) => e instanceof NavigationEnd),
    map(() => {
      const pages: AdminMenuDynamicPage[] = [];
      const courseMatch = router.url.match(/courses\/(.*?)\//);
      const bundleMatch = router.url.match(/bundles\/(.*?)\//);

      if (courseMatch && courseMatch?.input?.includes('lesson')) {
        const lessonId = localStorage.getItem('admin_menu_item_id');
        if (lessonId) {
          pages.push({
            icon: 'library_books',
            name: 'edit_lesson',
            url: oldAdminPanelUrl(
              `elearning/lesson/${lessonId}?college_id=${collegeEnv.collegeId}&access_token=${authRepository.snapshot.auth.access_token}`,
            ),
          });
        }
      } else if ((courseMatch && courseMatch?.input?.includes('exam')) || courseMatch?.input?.includes('practice')) {
        const questionnaireId = localStorage.getItem('admin_menu_item_id');
        if (questionnaireId) {
          pages.push({
            icon: 'library_books',
            name: 'edit_questionnaire',
            url: oldAdminPanelUrl(
              `elearning/questionnaire/${questionnaireId}?college_id=${collegeEnv.collegeId}&access_token=${authRepository.snapshot.auth.access_token}`,
            ),
          });
        }
      } else if (courseMatch && courseMatch?.input?.includes('survey')) {
        const surveyId = localStorage.getItem('admin_menu_item_id');
        if (surveyId) {
          pages.push({
            icon: 'library_books',
            name: 'edit_survey',
            url: oldAdminPanelUrl(
              `elearning/survey/${surveyId}?college_id=${collegeEnv.collegeId}&access_token=${authRepository.snapshot.auth.access_token}`,
            ),
          });
        }
      }

      if (courseMatch && courseMatch[1]) {
        pages.push({
          icon: 'video_library',
          name: 'Edit course',
          url: oldAdminPanelUrl(
            `elearning/courses/${courseMatch[1]}?college_id=${collegeEnv.collegeId}&access_token=${authRepository.snapshot.auth.access_token}`,
          ),
        });
      }

      if (bundleMatch && bundleMatch[1]) {
        pages.push({
          icon: 'stacks',
          name: 'Edit Bundle',
          url: oldAdminPanelUrl(
            `elearning/bundles/${bundleMatch[1]}?college_id=${collegeEnv.collegeId}&access_token=${authRepository.snapshot.auth.access_token}`,
          ),
        });
      }

      return pages;
    }),
  );
}
